<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">限时优惠</a>
          <i>></i>
          <a href="javascript:;">限时优惠列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div class="searchboxItem">
              <span class="itemLabel">活动名称:</span>
              <el-input
                v-model="activityName"
                type="text"
                size="small"
                style="width: auto"
                placeholder="请输入证书名称"
                clearable
              />
            </div>
            <div class="searchboxItem">
              <span class="itemLabel">创建时间:</span>
              <el-date-picker
                v-model="activityTime"
                size="small"
                type="daterange"
                placeholder="选择日期"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </div>
            <div class="searchboxItem ci-full">
              <span class="itemLabel">活动状态:</span>
              <el-select
                v-model="activityState"
                placeholder="请选择"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in activityStateList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div>
              <el-button class="bgc-bv" type="primary" @click="getData()"
                >查询</el-button
              >
              <el-button
                class="bgc-bv"
                type="primary"
                @click="EditDialog('add')"
                >新增</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :header-cell-style="tableHeader"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
            >
              <el-table-column label="序号" align="center" type="index" />
              <el-table-column
                label="活动名称"
                align="center"
                prop="activityName"
                :show-overflow-tooltip="true"
              />
              <el-table-column
                label="活动开始时间"
                align="center"
                prop="startTime"
                width="150"
              >
                <template slot-scope="scope">
                  {{ scope.row.startTime | moment }}
                </template>
              </el-table-column>
              <el-table-column
                label="活动结束时间"
                align="center"
                prop="endTime"
                width="150"
              >
                <template slot-scope="scope">
                  {{ scope.row.endTime | moment }}
                </template>
              </el-table-column>
              <el-table-column
                label="浏览人数"
                align="center"
                prop="browseNum"
              />
              <el-table-column label="购买人数" align="center" prop="payNum" />
              <el-table-column label="状态" align="center" prop="activityState">
                <template slot-scope="scope">
                  {{
                    $setDictionary("MINIACTIVITYSTATE", scope.row.activityState)
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="创建日期"
                align="center"
                prop="createTime"
                width="150"
              >
                <template slot-scope="scope">
                  {{ scope.row.createTime | moment }}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="280px">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="text"
                    @click="EditDialog('see', scope.row)"
                    >查看</el-button
                  >
                  <el-button
                    size="mini"
                    type="text"
                    :disabled="
                      scope.row.activityState == '40' ||
                      scope.row.activityState == '50'
                    "
                    @click="EditDialog('edit', scope.row)"
                    >编辑</el-button
                  >
                  <el-button
                    size="mini"
                    type="text"
                    :disabled="
                      scope.row.activityState == '20' ||
                      scope.row.activityState == '30' ||
                      scope.row.activityState == '40' ||
                      scope.row.activityState == '50'
                    "
                    @click="certificateRelease(scope.row.activityId)"
                    >发布</el-button
                  >
                  <el-button
                    size="mini"
                    type="text"
                    :disabled="
                      scope.row.activityState == '10' ||
                      scope.row.activityState == '40' ||
                      scope.row.activityState == '50'
                    "
                    @click="certificateStopEnrollment(scope.row.activityId)"
                    >停止</el-button
                  >
                  <!-- <el-button
                    size="mini"
                    type="text"
                    @click="certificateViewEnrollment(scope.row.certId)"
                    >查看订单</el-button
                  > -->
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @getData="getData"
          @sizeChange="sizeChange"
        />
      </div>
    </div>
    <el-dialog
      :title="DialogTitle"
      :visible.sync="DialogVisible"
      width="50%"
      center
      @close="DialogCancel"
    >
      <el-form
        ref="DialogFromRef"
        :model="DialogFrom"
        :rules="DialogFromRules"
        label-width="120px"
        size="small"
      >
        <el-form-item label="活动名称：" prop="activityName">
          <el-input
            v-model="DialogFrom.activityName"
            :disabled="seeStatu"
          ></el-input>
        </el-form-item>
        <el-form-item label="活动时间：" prop="activityTime">
          <el-date-picker
            v-model="DialogFrom.activityTime"
            :disabled="seeStatu || disabledSelect"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="课程：">
          <el-button @click="SelectCourse()" :disabled="seeStatu || disable != '10'"
            >选择课程</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        ref="multipleTable"
        :data="tableCourseData"
        :header-cell-style="tableHeader"
        :height="tableHeight"
        size="small"
        tooltip-effect="dark"
        style="width: 100%"
        @cell-mouse-enter="M_Enter"
        @cell-mouse-leave="m_leave"
      >
        <el-table-column label="序号" align="center" type="index" />
        <el-table-column
          label="课程名称"
          align="center"
          prop="courseName"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          label="课程分类"
          align="center"
          prop="courseTypeName"
        />
        <el-table-column label="原价格" align="center" prop="miniPrice" />
        <el-table-column label="活动价格" align="center" prop="activityPrice">
          <template slot-scope="scope">
            <div v-if="!scope.row.orderShow">
              <span> {{ scope.row.activityPrice || 0 }}</span>
              <i
                class="el-icon-edit"
                v-show="scope.row.showInput"
                @click="changeOrder(scope.row)"
              ></i>
            </div>
            <el-input
              v-else
              v-model="scope.row.activityPrice"
              maxlength="1000"
              size="small"
              @blur="
                () => {
                  editRowSort(scope.row, scope.$index);
                }
              "
            >
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              :disabled="disabledSelect"
              @click="deleteCourse(scope.$index)"
              >删除</el-button
            >
          </template>
        </el-table-column>
        <Empty slot="empty" />
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="DialogCancel">取 消</el-button>
        <el-button type="primary" @click="DialogSure()">确 定</el-button>
      </span>
    </el-dialog>
    <Course ref="Course" @eventBus="courseTableMoreBack" />
  </div>
</template>

<script>
import List from "@/mixins/List";
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import Course from "@/views/miniApp/limitedTimeOffer/popUp/courseMini.vue";
let editor = {};
export default {
  components: {
    Empty,
    PageNum,
    Course,
  },
  mixins: [List],
  data: function () {
    return {
      activityName: "", //活动名称
      activityTime: "", //活动时间
      activityState: "", //活动状态
      DialogTitle: "新增",
      DialogVisible: false,
      disabledSelect: false,
      seeStatu: false, // 查看
      options: [],
      DialogFrom: {
        activityName: "", //活动名称
        activityTime: "", //活动时间
      },
      DialogFromRules: {
        activityName: [
          { required: true, message: "请输活动名称", trigger: "blur" },
        ],
        activityTime: [
          { required: true, message: "请选择活动时间", trigger: "change" },
        ],
      },
      tableCourseData: [],
      activityStateList: [], //活动状态
    };
  },
  mounted() {},
  created() {
    this.getState();
  },
  methods: {
    // 获取数据字典
    getState() {
      const activityState = this.$setDictionary("MINIACTIVITYSTATE", "list");
      for (const key in activityState) {
        this.activityStateList.push({
          value: key,
          label: activityState[key],
        });
      }
    },
    // 鼠标进入事件
    M_Enter(row, column, cell, event) {
      if (column.label == "活动价格") {
        row.showInput = true;
      }
    },
    // 鼠标离开事件
    m_leave(row, column, cell, event) {
      row.showInput = false;
    },
    // 点击笔
    changeOrder(row) {
      if (!this.seeStatu) {
        row.orderShow = !row.orderShow;
      }
    },
    // 输入框失去焦点 保存修改
    editRowSort(row, index) {
      row.orderShow = !row.orderShow;
      this.tableCourseData[index].activityPrice = row.activityPrice;
    },
    // 获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.activityTime) {
        params.queryStartTime = this.activityTime[0];
        params.queryEndTime = this.activityTime[1];
      }
      if (this.activityName) {
        params.activityName = this.activityName;
      }
      if (this.activityState) {
        params.activityState = this.activityState;
      }
      this.doFetch(
        {
          url: "/mini/activity/pageList",
          params,
          pageNum,
        },
        true,
        2
      );
    },
    // 新增||编辑||查看
    EditDialog(stu, row) {
      this.DialogVisible = true;
      if (stu == "edit") {
        this.disable = row.activityState;
        if (row.activityState == "20" || row.activityState == "30") {
          this.disabledSelect = true;
        } else {
          this.disabledSelect = false;
        }
        this.DialogTitle = "编辑";
        this.getInfo(row.activityId);
      } else if (stu == "see") {
        this.seeStatu = true;
        this.disabledSelect = true;
        this.DialogTitle = "查看";
        this.getInfo(row.activityId);
      } else {
        this.DialogTitle = "新增";
        this.disable = "10";
        this.disabledSelect = false;
      }
      console.log(stu, this.disabledSelect);
    },
    // 获取编辑数据
    getInfo(activityId) {
      this.$post(
        "/mini/activity/getInfo",
        {
          activityId,
        },
        3000,
        true,
        2
      )
        .then((ret) => {
          this.DialogFrom = {
            ...this.DialogFrom,
            activityName: ret.data.activityName,
            activityId: ret.data.activityId,
            activityTime: [
              ret.data.startTime.replaceAll("/", "-"),
              ret.data.endTime.replaceAll("/", "-"),
            ],
          };
          ret.data.courseList.map((el) => {
            this.tableCourseData.push({
              courseName: el.courseName,
              courseId: el.courseId,
              courseTypeName: el.courseTypeName,
              miniPrice: el.miniPrice,
              orderShow: false,
              showInput: false,
              activityPrice: el.activityPrice,
              relationId: el.relationId,
            });
          });
        })
        .catch((err) => {
          return;
        });
    },
    // 选择课程
    SelectCourse() {
      this.$refs.Course.showPopUp(this.tableCourseData);
    },
    courseTableMoreBack(ret) {
      this.tableCourseData = [];
      ret.map((el) => {
        this.tableCourseData.push({
          courseName: el.courseName,
          courseId: el.courseId,
          courseTypeName: el.courseTypeName,
          miniPrice: el.miniPrice,
          orderShow: el.orderShow,
          showInput: el.showInput,
          activityPrice: el.activityPrice || 0,
          relationId: el.relationId || "",
        });
      });
      console.log(this.tableCourseData);
    },
    deleteCourse(index) {
      this.tableCourseData.splice(index, 1);
    },
    // 确认 - 新增 || 编辑
    DialogSure() {
      // 查看模式
      if (this.seeStatu) {
        this.seeStatu = false;
        this.DialogVisible = false;
        return;
      }
      this.$refs.DialogFromRef.validate((valid) => {
        if (valid) {
          if (!this.tableCourseData.length) {
            this.$message.error("请选择活动课程");
            return false;
          }
          let courseList = [];
          this.tableCourseData.map((el) => {
            console.log(el);
            courseList.push({
              activityPrice: el.activityPrice,
              courseId: el.courseId,
            });
          });
          let data = {
            activityName: this.DialogFrom.activityName, // 证书名称
            courseList,
          };
          if (this.DialogFrom.activityTime) {
            data.startTime = this.DialogFrom.activityTime[0];
            data.endTime = this.DialogFrom.activityTime[1];
          }

          if (this.DialogFrom.activityId) {
            data.activityId = this.DialogFrom.activityId;
          }
          this.$post(
            this.DialogFrom.activityId
              ? "/mini/activity/modify"
              : "/mini/activity/insert",
            data,
            3000,
            true,
            2
          )
            .then((ret) => {
              if (ret.status == 0) {
                this.$message.success("保存成功");
                this.empty();
                this.DialogVisible = false;
                this.getData(-1);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          return false;
        }
      });
    },
    //新增取消
    DialogCancel() {
      this.empty();
      this.DialogVisible = false;
      this.seeStatu = false;
    },
    empty() {
      this.DialogFrom = {
        activityName: "", //活动名称
        activityTime: "", //活动时间
      };
      this.tableCourseData = [];

      this.$refs.DialogFromRef.resetFields();
    },
    //发布
    certificateRelease(activityId) {
      this.$post("/mini/activity/state", { activityId }, 3000, true, 2).then(
        (ret) => {
          if (ret.status == "0") {
            this.$message.success("发布成功");
            this.getData(-1);
          }
        }
      );
    },
    //终止招生
    certificateStopEnrollment(activityId) {
      this.$post("/mini/activity/state", { activityId }, 3000, true, 2).then(
        (ret) => {
          if (ret.status == "0") {
            this.$message.success("已停止");
            this.getData(-1);
          }
        }
      );
    },
    //查看报名学员
    certificateViewEnrollment(certId) {
      this.$router.push({
        path: "/web/certificateStudentList",
        query: {
          certId: certId,
        },
      });
    },
  },
};
</script>

<style>
</style>