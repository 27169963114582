<template>
  <el-dialog
    :visible.sync="dialogVisible"
    top="1%"
    width="60%"
    :center="true"
    title="选择课程"
    :before-close="doClose"
  >
    <section class="CourseTablekponit">
      <div class="operationControl" style="border: 0">
        <div class="searchbox">
          <div title="课程名称" class="searchboxItem ci-full">
            <span class="itemLabel">课程名称:</span>
            <el-input
              v-model="courseName"
              type="text"
              size="small"
              clearable
              placeholder="请输入课程名称"
            />
          </div>
          <div>
            <el-button class="bgc-bv" @click="getData()">查询</el-button>
          </div>
        </div>
      </div>
      <div class="framePage-scroll">
        <div class="ovy-a">
          <el-table
            ref="multipleTable"
            :data="tableData"
            :height="tableHeight"
            size="small"
            tooltip-effect="dark"
            row-key="courseId"
            style="width: 100%"
            @selection-change="change"
            :header-cell-style="tableHeader"
            stripe
          >
            <el-table-column
              type="selection"
              width="50px"
              align="center"
              :reserve-selection="true"
            >
            </el-table-column>
            <el-table-column
              label="序号"
              align="center"
              type="index"
              width="100px"
              :index="indexMethod"
            />
            <el-table-column
              label="课程名称"
              align="center"
              prop="courseName"
              show-overflow-tooltip
            />

            <el-table-column
              label="课程分类"
              align="center"
              prop="courseTypeName"
              show-overflow-tooltip
            />
            <el-table-column label="创建时间" align="center" width="120">
              <template slot-scope="scope">
                <span>
                  {{ scope.row.createTime | momentDate }}
                </span>
              </template>
            </el-table-column>
            <Empty slot="empty" />
          </el-table>
        </div>
      </div>
      <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      <span class="dialog-footer" style="padding: 15px">
        <div>
          <el-button class="bgc-bv" @click="dialogClose">取消</el-button>
          <el-button class="bgc-bv" @click="doOk">确定</el-button>
        </div>
      </span>
    </section>
  </el-dialog>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "courseList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      dialogVisible: false,
      courseName: "",
      multipleTable: [],
      courseList: [],
    };
  },
  computed: {},
  created() {
    this.getTableHeight();
  },
  methods: {
    showPopUp(courseList) {
      this.dialogVisible = true;
      this.multipleTable = courseList;
      this.courseList = courseList;
      this.$nextTick(() => {
        this.multipleTable.forEach((v, i) => {
          this.tableData.forEach((_v, _i) => {
            if (v.courseId == _v.courseId) {
              this.$refs.multipleTable.toggleRowSelection(_v, true);
            }
          });
        });
      });
    },
    // selectable(row, index) {
    //   let arr = [];
    //   this.courseList.map((el) => {
    //     arr.push(el.courseId);
    //   });
    //   if (arr.includes(row.courseId)) {
    //     return false;
    //   } else {
    //     return true;
    //   }
    // },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        courseName: this.courseName,
      };
      this.doFetch(
        {
          url: "minicourse/choiceList",
          params,
          pageNum,
        },
        true,
        2
      );
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 0.675) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    change(rowArr) {
      this.multipleTable = rowArr;
    },
    doOk() {
      if (this.courseList) {
        this.courseList.map((el) => {
          this.multipleTable.map((el1) => {
            if (el.courseId == el1.courseId) {
              el1.activityPrice = el.activityPrice;
              el1.relationId = el.relationId;
            }
          });
        });
      }
      const { multipleTable } = this;

      if (multipleTable.length) {
        if (multipleTable.length > 5) {
          this.$message({
            message: "最多只能选择5个课程",
            type: "warning",
          });
        } else {
          this.$emit("eventBus", multipleTable);
          this.dialogVisible = false;
          this.multipleTable = [];
          this.$refs.multipleTable.clearSelection();
        }
      } else {
        this.$message({
          message: "请选择要关联的课件",
          type: "warning",
        });
      }
    },
    dialogClose() {
      this.dialogVisible = false;
      this.backArr = [];
      this.courseName = "";
      this.$refs.multipleTable.clearSelection();
      //   this.$emit("eventBus");
      // this.params = {};
    },
    doClose() {
      this.dialogVisible = false;
      this.backArr = [];
      this.courseName = "";
      this.$refs.multipleTable.clearSelection();
      //   this.$emit("eventBus");
      // this.params = {};
    },
  },
};
</script>
<style lang="less" scoped>
.CourseTablekponit {
  .el-radio {
    .el-radio__label {
      display: none;
    }
    .el-radio__inner {
      width: 18px;
      height: 18px;
      border-color: #777;
    }
    .el-radio__inner:hover {
      border-color: #5c6be8;
    }
    .el-radio__input.is-checked .el-radio__inner {
      border-color: #5c6be8;
      background-color: #5c6be8;
    }
  }
}
</style>
